// import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';

// Import Swiper and modules
import Swiper from 'swiper';
import { A11y, Autoplay, EffectCoverflow, Keyboard, Navigation } from 'swiper/modules';
import type { SwiperOptions } from 'swiper/types';

document.addEventListener('DOMContentLoaded', () => {
  const swiperSolucoesElement = document.querySelector('[swiper-solucoes]');
  const swiperLightboxElement = document.querySelector('.swiper-lightbox');
  const swiperLightboxThumbsElement = document.querySelector('.swiper-lightbox-thumbs');

  if (swiperSolucoesElement) {
    const swiperSolucoesParams: SwiperOptions = {
      modules: [A11y, Autoplay, EffectCoverflow, Keyboard, Navigation],
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 1,
      slideToClickedSlide: true,
      loop: true,
      allowTouchMove: true,
      createElements: true,
      navigation: true,
      speed: 500,
      loopAdditionalSlides: 2,
      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
      },

      coverflowEffect: {
        depth: 250,
        rotate: 7,
        slideShadows: true,
      },

      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },

      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },

      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 991px
        478: {
          slidesPerView: 1.2,
        },
        767: {
          slidesPerView: 1.5,
        },
        991: {
          slidesPerView: 2,
        },
        1279: {
          slidesPerView: 2.8,
        },
      },

      on: {
        slideChangeTransitionStart: function () {
          updateActiveSlide();
        },
        init: function () {
          updateActiveSlide();
        },
        touchEnd: function () {
          updateActiveSlide();
        },
      },
    };
    const swiper = new Swiper('[swiper-solucoes]', swiperSolucoesParams);
    swiper.init();

    function updateActiveSlide() {
      // Get all slides and pagination items
      const allSlides = document.querySelectorAll('[swiper-slide]');
      const allPaginationItems = document.querySelectorAll('.solucoes-pagination_item');

      // Get the currently active slide
      const activeSlide = document.querySelector('.swiper-slide-active');
      if (!activeSlide) return;

      // Get the name of the active slide
      const activeSlideName = activeSlide.getAttribute('solucoeslink_name');

      // Iterate over all slides and update the classes
      allSlides.forEach((slide) => {
        const slideTitle = slide.querySelector('.swiper-slide-title');
        if (slideTitle) {
          if (slide === activeSlide) {
            slideTitle.classList.remove('outline-white');
          } else {
            slideTitle.classList.add('outline-white');
          }
        }
      });

      // Iterate over all pagination items and update the classes
      allPaginationItems.forEach((item) => {
        const paginationName = item.getAttribute('solucoeslink_name');
        const paginationTitle = item.querySelector('.solucoes-pagination_title');
        if (paginationTitle) {
          if (paginationName === activeSlideName) {
            paginationTitle.classList.remove('outline-white');
          } else {
            paginationTitle.classList.add('outline-white');
          }
        }
      });
      const paginationItems = document.querySelectorAll('.solucoes-pagination_item');
      paginationItems.forEach((item) => {
        item.addEventListener('mouseenter', () => {
          const targetName = item.getAttribute('solucoeslink_name');
          const targetIndex = Array.from(swiper.slides).findIndex(
            (slide) => slide.getAttribute('solucoeslink_name') === targetName
          );

          if (targetIndex !== -1) {
            swiper.slideTo(targetIndex);
          }
        });
      });
    }
  } else {
    console.error('Swiper element not found');
  }

  if (swiperLightboxElement && swiperLightboxThumbsElement) {
    const swiperLightboxParams: SwiperOptions = {
      modules: [A11y, Autoplay, EffectCoverflow, Keyboard, Navigation],
      effect: 'fade',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 1,
      slideToClickedSlide: true,
      loop: true,
      allowTouchMove: true,
      createElements: true,
      navigation: true,
      speed: 500,

      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },

      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    };

    const swiperLightboxThumbsParams: SwiperOptions = {
      modules: [A11y, Autoplay, EffectCoverflow, Keyboard, Navigation],
      effect: 'fade',
      centeredSlides: true,
      slidesPerView: 5,
      slideToClickedSlide: true,
      loop: true,
      navigation: true,
      speed: 500,

      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },

      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    };

    const swiperLightbox = new Swiper('.swiper-lightbox', swiperLightboxParams);
    const swiperLightboxThumbs = new Swiper('.swiper-lightbox-thumbs', swiperLightboxThumbsParams);
    swiperLightbox.controller.control = swiperLightboxThumbs;
    swiperLightbox.init();
    swiperLightboxThumbs.init();
  } else {
    console.error('Swiper element not found');
  }
});
